<template>
  <v-radio-group v-model="currentValue" class="df-radio-button" hide-details>
    <template #label>
      <h1 class="button__label">{{ label }}</h1>
    </template>
    <v-radio
      v-for="(option, index) in options"
      :key="index"
      color="#39AF49"
      :value="option.value"
    >
      <template #label>
        <span class="option__label">{{ option.label }}</span>
      </template>
    </v-radio>
  </v-radio-group>
</template>

<script>
export default {
  name: 'DfRadioButton',

  props: {
    label: {
      required: true,
      type: String,
    },
    options: {
      required: true,
      type: Array,
      validator(value) {
        return value.every((item) => {
          return Object.keys(item).includes('label', 'value')
        })
      },
    },
    value: {
      default: null,
      type: [String, Boolean],
    },
  },

  computed: {
    currentValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.df-radio-button {
  margin: 0px;
  padding: 0px;

  .v-radio:not(:last-child) {
    margin-bottom: 16px;
  }
  .button__label {
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .option__label {
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
::v-deep .v-label {
  color: #1a2b46;
  font-family: 'Rubik';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
::v-deep .v-icon {
  width: 20px !important;
}
::v-deep .v-input--selection-controls__ripple {
  height: 22px;
  width: 22px;
  top: 1px;
  left: -1px;
  margin: 0px;
}
</style>
